define("additive-account/routes/instances/me/inbox", ["exports", "additive-account/config/environment", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _environment, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    model() {
      return this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/notifications`).then(response => response.json()).then(data => {
        return data.notifications;
      });
    },
    actions: {
      /**
       * Mark a message as read when the dialog-detail is closed
       */
      markMessageAsRead(id) {
        const detailModel = this.currentModel.findBy('id', id);
        if (detailModel && !detailModel.readAt) {
          (0, _object.set)(detailModel, 'readAt', true);
        }
      }
    }
  });
});