define("additive-account/templates/instances/me/inbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiContentViews @title={{t "global.messages"}} @theme="grey" as |view|>
    <view.navbar />
  
    <view.intro @title={{t "global.messages"}} @size="xl" @isMultiline={{true}} />
  
    <view.count @totalCount={{this.model.length}} @class="mt1" />
  
    <view.content>
      <UiList @items={{this.model}} as |list item|>
        <list.empty>
          <div class="empty-state w-100 flex flex-column items-center pa4 font-sm">
            {{svg-jar "empty-message" class="w-100" height="256"}}
            <div class="font-lg primary pb2">
              {{t "routes.messages.empty"}}
            </div>
          </div>
        </list.empty>
  
        <list.item
          @title={{item.data.title}}
          @description={{get item.data.text "0.text"}}
          @state={{if item.readAt "" "success"}}
          @linkTo="instances.me.inbox.message"
          @linkToParam={{item.id}}
          as |controls|
        >
          {{controls.state}}
        </list.item>
      </UiList>
    </view.content>
  
    <view.footer @classNames="views__footer w-100" />
  </UiContentViews>
  
  {{outlet}}
  */
  {
    "id": "TecZ7Ulb",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"global.messages\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@size\",\"@isMultiline\"],[[28,[37,1],[\"global.messages\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"count\"]],null,[[\"@totalCount\",\"@class\"],[[30,0,[\"model\",\"length\"]],\"mt1\"]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@items\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"empty\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"empty-state w-100 flex flex-column items-center pa4 font-sm\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[\"empty-message\"],[[\"class\",\"height\"],[\"w-100\",\"256\"]]]],[1,\"\\n          \"],[10,0],[14,0,\"font-lg primary pb2\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"routes.messages.empty\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@title\",\"@description\",\"@state\",\"@linkTo\",\"@linkToParam\"],[[30,3,[\"data\",\"title\"]],[28,[37,4],[[30,3,[\"data\",\"text\"]],\"0.text\"],null],[52,[30,3,[\"readAt\"]],\"\",\"success\"],\"instances.me.inbox.message\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"state\"]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,[[\"@classNames\"],[\"views__footer w-100\"]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null]],[\"view\",\"list\",\"item\",\"controls\"],false,[\"ui-content-views\",\"t\",\"ui-list\",\"svg-jar\",\"get\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-account/templates/instances/me/inbox.hbs",
    "isStrictMode": false
  });
});