define("additive-account/templates/components/ui-app", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiIconButton
    @class="absolute top-2 right-0"
    @icon="close"
    @isSecondary={{true}}
    @onClick={{action this.onRemove}}
  />
  <div class="mr05">
    {{#if this.app.appKey}}
      {{svg-jar this.app.appKey class="mw3 pr2 h3"}}
    {{/if}}
  </div>
  
  <div class="ui-app__description flex flex-column">
    <div class="app-title font-lg primary">
      {{t (concat "appNames." this.app.appId ".name")}}
    </div>
    {{#if this.app.amount}}
      <div class="app-price font-sm blue">
        {{ui-format-currency this.app.amount "EUR" "de" 0 0 100}}
      </div>
    {{/if}}
    <div class="app-description font-sm secondary">
      {{t (concat "appDetails." this.app.appId ".description")}}
    </div>
  </div>
  */
  {
    "id": "8Q6uF69m",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@icon\",\"@isSecondary\",\"@onClick\"],[\"absolute top-2 right-0\",\"close\",true,[28,[37,1],[[30,0],[30,0,[\"onRemove\"]]],null]]],null],[1,\"\\n\"],[10,0],[14,0,\"mr05\"],[12],[1,\"\\n\"],[41,[30,0,[\"app\",\"appKey\"]],[[[1,\"    \"],[1,[28,[35,3],[[30,0,[\"app\",\"appKey\"]]],[[\"class\"],[\"mw3 pr2 h3\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"ui-app__description flex flex-column\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"app-title font-lg primary\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[28,[37,5],[\"appNames.\",[30,0,[\"app\",\"appId\"]],\".name\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"app\",\"amount\"]],[[[1,\"    \"],[10,0],[14,0,\"app-price font-sm blue\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[[30,0,[\"app\",\"amount\"]],\"EUR\",\"de\",0,0,100],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"app-description font-sm secondary\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[28,[37,5],[\"appDetails.\",[30,0,[\"app\",\"appId\"]],\".description\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"ui-icon-button\",\"action\",\"if\",\"svg-jar\",\"t\",\"concat\",\"ui-format-currency\"]]",
    "moduleName": "additive-account/templates/components/ui-app.hbs",
    "isStrictMode": false
  });
});